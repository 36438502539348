import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/earns/admin/transfers', {
      params,
    })
    return response
  },
}
