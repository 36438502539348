<template>
  <div v-if="isOpen">
    <b-modal
      centered
      title="Transfer Detail"
      v-model="isOpen"
      id="modal-lg"
      size="lg"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <b-row class="pb-2">
          <b-col md="2">
            <label for="">User id</label>
          </b-col>
          <b-col md="8">
            <b-input :value="getTitle(model.user_id)" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Currency</label>
          </b-col>
          <b-col md="8">
            <b-input :value="CURRENCIES[model.currency - 1]" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Amount</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.amount" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Fee</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.fee" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Source id</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.source_id" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Transfer side</label>
          </b-col>
          <b-col md="8">
            <b-input :value="TRANSFER_SIDES[model.side]" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Status</label>
          </b-col>
          <b-col md="8">
            <b-input :value="STATUS[model.status]" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Version</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.version" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Occur Date</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.occur_date | local_time_string" disabled />
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
const STATUS = ['INACTIVE', 'ACTIVE', 'PENDING']
const CURRENCIES = ['TOKEN', 'GEM']
const TRANSFER_SIDES = ['OUT', 'IN']
export default {
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      STATUS,
      CURRENCIES,
      TRANSFER_SIDES
    };
  },
  methods: {
    async openDetailModal(transfer){
      this.model = transfer;
      this.isOpen = true;
      this.isUpdate = true;
    },

    cancel(){
      this.isOpen = false;
      this.isUpdate = false;
      this.model = {};
    },

    getTitle(url) {
      return url ? url.slice(8) : ''
    },

    getBody(data) {
      if (typeof data === 'object') {
        const keys = Object.keys(data)
        if (keys.length === 1 && keys[0] === 'data') {
          data = JSON.stringify(JSON.parse(data.data))
          return data
        }
      }
      return JSON.stringify(data)
    }
  },
};
</script>
<style lang="scss" scoped>
  label {
    font-size: 16px;
    margin-top: 0.3rem;
    margin-bottom: 0;
  }
</style>
