<template>
<div>
  <page-breadcrumb title="Transfer records" class="mb-1" />
  <b-card class="page-content">
    <div class="d-flex align-items-end">
      <b-form-input class="w-25" label="User id"
        v-model="user_id"
        id="user_id"
        type="text"
        placeholder="User id"
      />
      <b-form-input class="mx-3 w-25" label="Source id"
        v-model="source_id"
        id="source_id"
        type="text"
        placeholder="Source id"
      />
      <loading-button
        variant="primary"
        icon="RefreshCcwIcon"
        :is-loading="isLoading"
        @click="getResults"
      >
        Search
      </loading-button>
    </div>

    <section class="py-3">
      <transfer-table :display-results="displayResults" :is-loading="isLoading" />
    </section>

    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="currentPage"
      :total-rows="totalItems"
      :per-page="itemsPerPage"
    />
  </b-card>
</div>
</template>

<script>
import service from "../service"
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import TransferTable from './_components/TransferTable.vue'

export default {
  components: {
    DatePicker,
    TransferTable
  },

  data() {
    return {
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 30,
      displayResults: [],
      title: null,
      isLoading: false,
      query: null,
      user_id: '',
      source_id: '',
      defaultQuery: {}
    };
  },

  watch: {
    currentPage() {
      this.getResults();
    },
  },

  created() {
    this.getResults();
  },

  methods: {
    async getResults() {
      this.isLoading = true
      // let query = JSON.parse(JSON.stringify(this.query))
      this.query = JSON.parse(JSON.stringify(this.defaultQuery))
      if (this.user_id) {
        this.query['user_id'] = this.user_id
      }
      if (this.source_id) {
        this.query['source_id'] = this.source_id
      }
      let response = await service.getList({
        query: this.query,
        page: this.currentPage,
        limit: this.itemsPerPage
      });
      let results = [];
      if (response.data.type === "DATA") {
        results = response.data.data.list
        this.displayResults = results
        this.totalItems = response.data.data.total
      }
      this.isLoading = false
    }
  },
};
</script>
